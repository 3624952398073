import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';
import { updateContent } from './contents';
import Slide from '@material-ui/core/Slide';
import { toggleLoadingTrue, toggleLoadingFalse } from './deleteLoading';

const initialState = {
  magazines: [],
  magazine:{
    status:'idle',
    error:null
  },
  meta: {},
  status: 'idle',
  error: null,
};

export const fetchMagazines = createAsyncThunk(
  'magazines/fetchMagazines',
  async ({ page, instructorId, search }) => {
    let data;
    try {
      const response = await axios.get(
        `/instructor/content-magazines?page=${page}&search=instructor.id:${instructorId};name:${search}&searchJoin=and&orderBy=id&sortedBy=desc`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addNewMagazine = createAsyncThunk(
  'magazines/addNewMagazine',
  async (query, thunkAPI) => {
    const { values } = query;
    let data;
    try {
      const response = await axios.post(`/instructor/content-magazines`, values);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('add-magazine-modal'));
        // console.log(data.payload);
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const editMagazine = createAsyncThunk('magazines/editMagazine', async (query, thunkAPI) => {
  const { values, magazineId, chapterSlug, chapterId, external } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/content-magazines/${magazineId}`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-magazine-modal'));
      if (external) {
        thunkAPI.dispatch(
          updateContent(
            'magazine',
            { ...data.payload, content: data.payload },
            chapterSlug,
            chapterId
          )
        );
      }
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const deleteMagazine = createAsyncThunk('magazines/deleteMagazine', async (query, thunkAPI) => {
  const { magazineId } = query;
  let data;
  try {
    thunkAPI.dispatch(toggleLoadingTrue());
    const response = await axios.delete(`/instructor/content-magazines/${magazineId}`);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('delete-modal'));
      thunkAPI.dispatch(toggleLoadingFalse());
      window.location = '/magazines';
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(toggleLoadingFalse());
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const deleteAttachmentsFromMagazine = createAsyncThunk(
  'magazines/deleteAttachmentsFromMagazine',
  async (query, thunkAPI) => {
    const { contentFileId } = query;
    let data;
    try {
      const response = await axios.delete(`/instructor/file/${contentFileId}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('add-magazine-modal'));
        window.location = '/magazines';
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getMagazineTab = (chapters, chapterSlug) => {
  if (chapters == null) {
    return null;
  }
  if (chapters.length <= 0) {
    return null;
  }
  const selectedChapter = chapters.find((item) => item.chapter.slug === chapterSlug);
  if (selectedChapter == null) {
    return null;
  }
  if (selectedChapter.tab != null) {
    return selectedChapter.tab;
  }
  if (selectedChapter.section != null) {
    return selectedChapter.section.tab;
  }
  return null;
};

export const getMagazineSection = (chapters, chapterSlug) => {
  if (chapters == null) {
    return null;
  }
  if (chapters.length <= 0) {
    return null;
  }
  const selectedChapter = chapters.find((item) => item.chapter.slug === chapterSlug);
  if (selectedChapter == null) {
    return null;
  }
  if (selectedChapter.section != null) {
    return selectedChapter.section;
  }
  return null;
};

export const getMagazineInformations = (data) => {
  if (data.content_files.length > 0) {
    return data.content_files;
  }
  return [];
};

export const formatMagazine = (data, chapterSlug) => {
  // - slug, type, levels, attachments.
  const selectedChapter = data.chapter_contents.find(
    (chapter) => chapter?.chapter?.slug === chapterSlug
  );
  const newData = {
    id: data.id,
    slug: data.slug,
    created_at: data.created_at,
    title: data.name,
    description: data.description,
    is_active: data.active,
    is_draft: data.is_draft,
    is_rtl: data.rtl,
    is_downloadable: data.downloadable,
    position: chapterSlug != null ? selectedChapter.position : data.position,
    type: data.type,
    tab: getMagazineTab(data.chapter_contents, chapterSlug),
    section: getMagazineSection(data.chapter_contents, chapterSlug),
    teacher: data.instructor,
    chapters: data.chapter_contents,
    scope: data.scope,
    magazine: getMagazineInformations(data),
  };
  return newData;
};

const slice = createSlice({
  name: 'magazines',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMagazines.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchMagazines.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      const newMagazines = action.payload.payload.map((magazine) => {
        return formatMagazine(magazine);
      });
      state.magazines = newMagazines;
      state.meta = action.payload.meta;
    },
    [fetchMagazines.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [addNewMagazine.pending]: (state, action) => {
      state.magazine.status = 'loading';
    },
    [addNewMagazine.fulfilled]: (state, action) => {
      const data = action.payload;
      state.magazines.push(formatMagazine(data));
      state.magazine.status = 'succeeded'

    },
    [addNewMagazine.rejected]: (state, action) => {
      state.magazine.status = 'failed';
      state.magazine.error = action.payload;
    },
    [editMagazine.fulfilled]: (state, action) => {
      state.magazine.status = 'succeeded'
      if (action.meta.arg.external) {
        // console.log(action.meta.arg.external);
        return;
        
      }
      const data = action.payload;
      const index = state.magazines.findIndex((item) => item.id === action.payload.id);
      state.magazines[index] = formatMagazine(data);
      state.status= 'succeeded'
    },
    [editMagazine.pending]: (state, action) => {
      state.magazine.status = 'loading';
    },
    [editMagazine.rejected]: (state, action) => {
      state.magazine.status = 'failed';
      state.magazine.error = action.payload;
    },
    [deleteMagazine.fulfilled]: (state, action) => {
      const data = action.payload;   
    },
    [deleteMagazine.pending]: (state, action) => {
      state.magazine.status = 'loading';  
 
    },
    [deleteMagazine.rejected]: (state, action) => {
      state.magazine.status = 'failed';
      state.magazine.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
